<template>
  <Transition name="overlay-fade">
    <div
      v-if="overlay.visible.value"
      class="site-overlay"
      @click="close"
    />
  </Transition>
</template>

<script setup>
  import { useOverlay } from '@/components/SiteOverlay/useOverlay'

  const overlay = useOverlay()

  const close = () => {
    overlay.closeSubscribed()
  }
</script>

<style lang="scss">
  .overlay-fade-enter-active {
    transition: opacity 0.15s ease-in;
  }

  .overlay-fade-leave-active {
    transition: opacity 0.15s ease-out;
    transition-delay: 0.45s;
  }

  .overlay-fade-enter-from,
  .overlay-fade-leave-to {
    opacity: 0;
  }

  .site-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(6px);
  }
</style>